import React from 'react';
import { Grid, Text, Image } from '@nextui-org/react';
import { QRCode } from 'react-qrcode-logo';

export default function LabProgress(props) {
    return <Grid.Container gap={0} justify="center">
    <Grid xs={0} sm={0.5} justify="left">
      <a href="https://discord.gg/join-cloudflare-labs">
        <QRCode value="https://discord.gg/join-cloudflare-labs" logoImage="./discord.svg" removeQrCodeBehindLogo ecLevel="Q" />
      </a>
    </Grid>
    <Grid xs={12} sm={11} justify="center">
      <Grid.Container gap={0} justify="center">
        <Grid xs={12} sm={8} justify="center">
          <Image
            src="./cf-zt.svg"
            width='80hv'
            alt="Default Image"
            objectFit='cover'
          />
        </Grid>
        <Grid xs={12} justify="center">
          <Text h3 size={26} >
          Workshop Dashboard
          </Text>
        </Grid>
      </Grid.Container>
    </Grid>
    <Grid xs={0} sm={0.5} justify="right">
      <a href="https://lab.cfiq.io/en/workshops/getting-started">
        <QRCode value="https://lab.cfiq.io/en/workshops/getting-started"  logoImage="./lab.svg" removeQrCodeBehindLogo ecLevel="Q" />
      </a>
    </Grid>
  </Grid.Container>
}