import React from 'react';
import { Grid, Text, Progress } from '@nextui-org/react';

export default function LabItem(props) {
    return <>
             <Grid xs={12}>
               <Text>{props.lab.name}</Text>
             </Grid>
             <Grid xs={12}>
               <Progress
                 value={props.lab.items?.length/props.cohort_size*100}
                 color="primary"
                 size="lg"
               >{props.lab.items?.length > 0 ? <Text color="white" size={14} weight="extrabold">{props.lab.items?.length}</Text> : null}</Progress>
             </Grid>
           </>;
}