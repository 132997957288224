import React from 'react';
import { Grid, Text, Card } from '@nextui-org/react';

export default function TotalCompletion(props) {
    return <Grid xs={12} sm={4} justify="center">
             <Card
               isPressable
               isHoverable
               css={{ mw: "500px" }}
             >
               <Card.Body>
                 <Grid.Container gap={0.85} justify="center">
                   <Grid xs={12} justify="center">
                     <Text h2 size={20} >
                       Total Completion
                     </Text>
                   </Grid>
                   <Grid xs={12} justify="center">
                     <Text h2 size={68} >
                       {Math.round(props.total_completion)}%
                     </Text>
                   </Grid>
                 </Grid.Container>
               </Card.Body>
             </Card>
           </Grid>;
}