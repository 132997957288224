import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Card, Text } from '@nextui-org/react';
import LabHeader from './LabHeader.js';
import LabProgress from './LabProgress.js';
import TotalPoints from './TotalPoints.js';
import TotalCompletion from './TotalCompletion.js';
//import TopUsers from './TopUsers.js';

//const base_url = 'http://localhost:8000';
const base_url = 'https://progress.cfiq.io';

/*const labs = [
    {
      name: 'ZT 301｜ Lab I',
      content: [
        {
          name: 'Identity & Authentication',
          items: []
        },
        {
          name: 'Intro to the WARP client | Device posture',
          items: []
        },
        {
          name: 'Tunnel & Zero Trust Private Network Access',
          items: []
        },
        {
          name: 'Render an in-browser SSH terminal',
          items: []
        },
      ]
    },
    {
      name: 'ZT 301｜ Lab II',
      content: [
        {
          name: 'Clientless Browser Isolation & ZT App Launcher',
          items: []
        },
        {
          name: 'Configure Gateway Policies',
          items: []
        },
        {
          name: 'Configure Gateway with a Proxy',
          items: []
        },
        {
          name: 'Configure Tenant Control',
          items: []
        },
      ]
    }
  ];*/
  
  /*const top_users = [
    {
      name: 'ancient-uncle',
      points: '350',
      completion: '50%',
      time: '4:12pm'
    },
    {
      name: 'lucky-basket',
      points: '250',
      completion: '40%',
      time: '3:23pm'
    },
    {
      name: 'wise-duck',
      points: '150',
      completion: '25%',
      time: '3:50pm'
    }
  ];*/

  const refresh = () => window.location.reload(true)

export default function Dashboard(props) {
    const [status, setStatus] = useState("idle");  // ToDo: Add error rendering if status==rejected
    const [progress, setProgress] = useState([]);
    const [totalCompletion, setTotalCompletion] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const cohort_size = 19; // number of participants to normalize the completion %

    const recalc = (progress) => {
        let total_points = 0;
        let total_labs = 0;
        let total_completed = 0;
        for (const section of progress) {
            for (const lab of section.content) {
                //total_points += Math.min(lab.points * lab.items.length, cohort_size);
                total_points += 100 * Math.min(lab.items.length, cohort_size); // for now hardcoding each lab to be awarded 100 points
                total_labs++;
                total_completed+=lab.items.length;
            }
        }
        setTotalPoints(total_points);
        setTotalCompletion(Math.min(1,total_completed/(total_labs*cohort_size)) * 100);
        //console.log(total_completed, total_labs , cohort_size);
    }

    // memoizing so the function can act as a dependency of useEffect
    const fetchProgress = useCallback(() => {
        setStatus("idle");
        fetch(`${base_url}/v1`)
          .then((res) => {
            if(res.status === 200) { return res.json() }
            else {
                console.error("Failed fetching data from worker:", res);
                setStatus("rejected1");
            }
          })
          .then((result) => { setProgress(result); recalc(result); })
          .catch((err) => {
            console.error("Failed processing data from worker:", err);
            setStatus("rejected2");
          });
        setInterval(fetchProgress, 90000);
    }, []);
    
    useEffect(() => {
        fetchProgress();
        
        // SSE
        /*
        const updateProgress = (data) => {
            const parsedData = JSON.parse(data);
            setProgress(parsedData);
            recalc(parsedData);
        };
        
        const eventSource = new EventSource(`${base_url}/sse`);

        eventSource.onmessage = (e) => updateProgress(e.data);
        return () => {
          eventSource.close();
        };*/
      }, [fetchProgress]);
    
    const err = <Grid.Container gap={4} justify="center">
        <Card css={{ p: "$6", mw: "400px", background:'#FAAD3F' }} isPressable isHoverable onPress={refresh}>
    <Card.Body css={{ py: "$2" }}>
      <Text color="#fff" weight="bold" justify="center">
        Cannot connect to a worker. Press to reload.
      </Text>
    </Card.Body>
  </Card></Grid.Container>;

    return <>
        <LabHeader />
        {status === 'rejected' ? err : <LabProgress labs={progress} cohort_size={cohort_size} />}
        {progress && progress.length>0 ? 
            <Grid.Container gap={4} justify="center">
                <TotalPoints total_points={totalPoints} />
                <TotalCompletion total_completion={totalCompletion} />
                {/*<TopUsers top_users={top_users} />*/}
            </Grid.Container>
            : null }
    </>;
}