import React from 'react';
import { Grid, Text, Loading } from '@nextui-org/react';
import LabItem from './LabItem.js';

export default function LabProgress(props) {
    let labs_dom = [];
    if(props.labs && props.labs.length > 0) {
      for (const section of props.labs) {
        let lab_list = [];
        for (const lab of section.content) {
          lab_list.push(<LabItem lab={lab} cohort_size={props.cohort_size} key={lab.name} />);
        }
        labs_dom.push(
          <Grid xs={12} sm={6} key={section.title}>
            <Grid.Container gap={0.5} justify="center">
              <Grid xs={12}>
                <Text h4 size={20}>{section.title}</Text>
              </Grid>
              {lab_list}
            </Grid.Container>
          </Grid>);
      }
      
      return <Grid.Container gap={4} justify="center">
              {labs_dom}
            </Grid.Container>;
    } else {
      return <Loading>Loading</Loading>;
    }

}