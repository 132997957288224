import React from 'react';
import { Grid, Text, Card } from '@nextui-org/react';
import { thousandsSeparator } from '../helper';

export default function TotalPoints(props) {
    return <Grid xs={12} sm={4} justify="center">
             <Card
               isPressable
               isHoverable
               css={{ mw: "500px" }}
             >
               <Card.Body>
                 <Grid.Container gap={1.3} justify="center">
                   <Grid xs={12} justify="center">
                       <Text h2 size={20} >
                         Total Points
                       </Text>
                   </Grid>
                   <Grid xs={12} justify="center">
                      <Text h2 size={50} >
                        {thousandsSeparator(props.total_points)} pts
                      </Text>
                   </Grid>
                 </Grid.Container>
               </Card.Body>
             </Card>
           </Grid>;
}